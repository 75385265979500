import { useEffect, useState } from 'react';
import { differenceInYears } from 'date-fns';
import { Pie, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
} from 'chart.js';
import { Flex, Box, Table } from '@radix-ui/themes';

ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement
);

function AgeAndGender({ customerData }) {
    const [genderCount, setGenderCount] = useState({ male: 0, female: 0 });
    const [ageList, setAgeList] = useState([]);
    const [ageData, setAgeData] = useState();
    const [genderData, setGenderData] = useState();

    const ageRanges = [
        { lower: 20, upper: 29, label: '20-29 yrs' },
        { lower: 30, upper: 39, label: '30-39 yrs' },
        { lower: 40, upper: 49, label: '40-49 yrs' },
        { lower: 50, upper: 59, label: '50-59 yrs' },
        { lower: 60, upper: 69, label: '60-69 yrs' },
        { lower: 70, upper: 78, label: '70-78 yrs' },
        { lower: 79, upper: 150, label: '79+ yrs' },
        { lower: 0, upper: 0, label: 'Unknown' },
    ];

    const ageOpts = {
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Age of Clients',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    const genderOpts = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                align: 'center',
                labels: {
                    color: 'darkred',
                    font: {
                        weight: 'bold',
                    },
                },
            },
            title: {
                display: true,
                position: 'top',
                text: 'Gender of clients',
                color: 'black',
                font: {
                    family: 'Open Sans,Helvetica,Arial,sans-serif',
                    weight: '400',
                    size: 18,
                    lineHeight: 1.42857,
                },
            },
        },
    };

    useEffect(() => {
        if (customerData?.data?.length > 0) {
            setGenderCount({
                male: customerData.data.filter((c) => c.gender === 'm').length,
                female: customerData.data.filter((c) => c.gender === 'f').length,
            });

            // Calculate ages
            const ageList = ageRanges.map((a) => {
                return { count: 0, label: a.label };
            });
            customerData?.data?.map((customer) => {
                const age = customer.dob ? differenceInYears(new Date(), customer.dob) : 0;

                const ageRange = ageRanges.find((a) => age >= a.lower && age <= a.upper);
                if (ageRange) {
                    const exists = ageList.findIndex((a) => a.label === ageRange.label);
                    if (exists > -1) {
                        ageList[exists].count++;
                    }
                } else {
                    const exists = ageList.findIndex((a) => a.label === 'Unknown');
                    if (exists > -1) {
                        ageList[exists].count++;
                    }
                }
            });
            setAgeList(ageList);
        }
    }, [customerData]);

    useEffect(() => {
        setAgeData({
            labels: ageList.map((a) => a.label),
            datasets: [
                {
                    label: 'Data',
                    data: ageList.map((a) => a.count),
                    backgroundColor: '#7ec7eb',
                },
            ],
        });
    }, [ageList]);

    useEffect(() => {
        setGenderData({
            labels: ['Female', 'Male'],
            datasets: [
                {
                    label: 'Gender of clients',
                    data: [genderCount?.female, genderCount?.male],
                    backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
                    borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
                    borderWidth: 1,
                },
            ],
        });
    }, [genderCount]);

    return (
        <>
            <Flex gap="3">
                <Box className="LeftColumn">
                    {ageList?.length > 0 && (
                        <Table.Root>
                            <Table.Body>
                                {ageList.map((a, idx) => (
                                    <Table.Row key={`report-key-${idx}`}>
                                        <Table.RowHeaderCell>{a.label}</Table.RowHeaderCell>
                                        <Table.Cell>{a.count}</Table.Cell>
                                    </Table.Row>
                                ))}
                                <Table.Row>
                                    <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                    <Table.Cell>
                                        {ageList.reduce(
                                            (acc, current) => acc + Number.parseInt(current.count),
                                            0
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table.Root>
                    )}
                </Box>
                <Box className="RightColumn" style={{ width: '800px' }}>
                    {ageData && <Bar data={ageData} options={ageOpts} />}
                </Box>
            </Flex>
            <Flex gap="3">
                <Box className="LeftColumn">
                    <Table.Root>
                        <Table.Body>
                            <Table.Row>
                                <Table.RowHeaderCell>Male</Table.RowHeaderCell>
                                <Table.Cell>{genderCount?.male}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.RowHeaderCell>Female</Table.RowHeaderCell>
                                <Table.Cell>{genderCount?.female}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.RowHeaderCell>&nbsp;</Table.RowHeaderCell>
                                <Table.Cell>{genderCount?.male + genderCount?.female}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table.Root>
                </Box>
                <Box className="RightColumn">
                    {genderData && <Pie data={genderData} options={genderOpts} />}
                </Box>
            </Flex>
        </>
    );
}

export default AgeAndGender;
